import React from 'react';
import apiConfig from '../config/apiConfig';

export const links = {
    privacy: 'https://onerx.com/full-privacy',
    terms: apiConfig.termsOfUseUrl
};

export function openLink(type, target = '_blank') {
    if (links[type]) {
        window.open(links[type], target);
    }
}

export function linkify(text, type) {
    return (
        <span style={{ cursor: 'pointer' }} onClick={() => openLink(type)}>
            {text}
        </span>
    );
}
