const apiConfig = {
    apiBaseUrl:
        window._env_.REACT_APP_API_ENDPOINT ||
        'https://default-trucheckapi-dev.truveris.com/',
    authConfig: window._env_.REACT_APP_AUTH_CONFIG || {
        publicAccessToken:
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhbGxvd2VkX25kYyI6IjAwNDU2MTQxMDMwIiwiY2xpZW50X25hbWUiOiJ0cmV2ZW5hbiIsImNsaWVudF90eXBlIjoicHVibGljIiwiaXNzIjoidHJ1Y2hlY2tfYXBpIiwic3ViIjoidHJ1Y2hlY2tfcHVibGljIiwidHlwZU9mQ2xpZW50IjoicHVibGljIn0.FXb1qXwJva5gK7HsngUGQe3oQDFYaDt-r_gWN9OYPFM'
    },
    copayCardUrl:
        window._env_.REACT_APP_COPAY_URL ||
        'https://trevenan-staging.truveris.com/',
    termsOfUseUrl:
        window._env_.REACT_APP_TERMS_OF_USE_URL ||
        'https://trevenan.truveris.com/terms-and-conditions/index.html'
};

export default apiConfig;
