export const CORE_MESSAGES = {
    '01': 'Incorrect RxBIN',
    '04': 'Missing or Incorrect RxPCN',
    '06': 'Missing or Incorrect RxGRP',
    '07': 'Incorrect Member ID',
    '08': 'Missing or Incorrect Person Code',
    11: 'Missing or Incorrect Policyholder',
    51: 'Missing or Incorrect RxGRP',
    52: 'Incorrect Member ID',
    N1: 'Incorrect Member ID'
};
