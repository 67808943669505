import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({
    name,
    width,
    height,
    folder = '',
    ext = 'png',
    ...restProps
}) => {
    return (
        <img
            src={require(`../../assets/images/${folder}${name}.${ext}`)}
            style={{ width, height }}
            alt={name}
            {...restProps}
        />
    );
};

Icon.propTypes = {
    name: PropTypes.string.isRequired,
    folder: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ext: PropTypes.string
};

export default Icon;
