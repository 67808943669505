const BRAND_ERRORS = {
    preAuth: { componentName: 'TrevenanPreAuth' },
    general:
        'We were not able to return a cost based on your information; please try again later.',
    network:
        'We could not retrieve your price for Trevenan <br> due to a network error <br> with your insurance company. Please try again later.'
};

export const BRAND_SPECIFIC_MESSAGES = {
    rejectCodesPriority: ['75', 'MR', '70', '65', '60', '66'],
    messageWord: 'XXXXX',
    messages: {
        75: BRAND_ERRORS.preAuth,
        MR: BRAND_ERRORS.preAuth,
        70: BRAND_ERRORS.preAuth,
        AJ: BRAND_ERRORS.preAuth,
        R6: BRAND_ERRORS.preAuth,
        76: BRAND_ERRORS.preAuth,
        88: BRAND_ERRORS.preAuth,
        608: BRAND_ERRORS.preAuth,
        A5: BRAND_ERRORS.preAuth,
        90: BRAND_ERRORS.network,
        91: BRAND_ERRORS.network,
        92: BRAND_ERRORS.network,
        95: BRAND_ERRORS.network,
        96: BRAND_ERRORS.network,
        97: BRAND_ERRORS.network,
        98: BRAND_ERRORS.network,
        99: BRAND_ERRORS.network,
        M3: BRAND_ERRORS.network,
        MZ: BRAND_ERRORS.network,
        NN: BRAND_ERRORS.network,
        default: BRAND_ERRORS.general
    }
};
