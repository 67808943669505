export const SUCCESS_SCREEN_CONFIG = {
    copayCardButtonText: 'Download a Savings Card',
    phoneNumber: 'XXX-XXX-XXXX',
    modalBrandName: 'TREVENAN',
    withInsurancePlusMessage: 'With Insurance PLUS our Savings Program',
    showWithInsurancePlusSymbol: false,
    governmentFooterMessage: {
        text: 'This space can be used to display brand specific messaging',
        link: false
    },
    copayFooterMessage: {
        text: 'This space can be used to display brand specific messaging',
        link: false
    },
    disclaimerMessage: {
        text: 'Please note: If you have government sponsored insurance, such as Medicare or Medicaid, you will not be eligible for our savings program.',
        link: false,
        replaceText: false
    }
};
