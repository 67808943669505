import React from 'react';
import './styles.scss';
import { linkify } from '../../utils/urlHelper';

const Footer = () => {
    return (
        <div
            data-e2e='footer-container'
            className='trevenan-footer d-flex flex-column justify-content-center align-items-center'
        >
            <div data-e2e='footer__info-container' className='bottom-links'>
                <span>{linkify('Privacy Policy', 'privacy')}</span>
                <span className='links-divider'>|</span>
                <span>{linkify('Terms of use', 'terms')}</span>
            </div>
            <p>TREVENAN is a licensed trademark of Truveris Inc.</p>
            <p>© 2020 Truveris Inc. All rights reserved. TRU-012345</p>
        </div>
    );
};

export default Footer;
