import React from 'react';
import './styles.scss';

const ContentLayout = ({ children }) => {
    return (
        <div data-e2e='content-container' className='trevenan-content'>
            <div className='widget'>{children}</div>
        </div>
    );
};

export default ContentLayout;
